<template>
  <div class="login">
    <div class="logo-top">
        <img style="height: 48px;width: 390px; margin-bottom: 50px;" src=".././assets/img/bg/topIcon.png" alt="logo" />
      </div>
    <div class="wrap">
      <div class="main">
        <div class="head">
          <img style="margin-right: 24px;height: 40px;width: 170px;" src=".././assets/img/bg/header.png" alt="logo" />
          <img style="margin-right: 10px;height: 20px;width: 70px;" src=".././assets/img/bg/Logo.png" alt="logo" />
        </div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-position="top"
        >
        <el-form-item label="账号：" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入账号" size="large" ></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="pwd">
            <el-input
              v-model="form.pwd"
              type="password"
              placeholder="请输入密码"
              size="large"
            ></el-input>
          </el-form-item>
          <div class="bottom-btn">
            <el-button @click="onReset" class="cancel-btn btn">重置</el-button>
            <el-button type="primary" class="btn"  @click="onSubmit">登录</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      form: {
        phone: "",
        pwd: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.user.loginIn(this.form).then((res) => {
            let { data, message, success } = res;
            if (success) {
              this.$store.commit("storeLoginInfo", data);
              localStorage.setItem("store", JSON.stringify(this.$store.state));
              localStorage.setItem("token", data.token);
              this.$router.push("/manage/shop/info");
            } else {
              this.$message.error(message);
            }
          });
        } else {
          this.$message.warning("请按提示信息正确填写");
        }
      });
    },

    onReset() {
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>

::v-deep .el-input__inner{
  background-color: #F2F5FA;
  border: 1px solid #E6E6E6;
}


.login {
  height: 100vh;
  background: url("../assets/img/bg/bg.png") no-repeat 0 0/100% 100% #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrap {
  width: 490px;
  height: 450px;
  background: rgba(255,255,255,0.65);
  border-radius: 32px 32px 32px 32px;
  padding: 40px;
  box-sizing: border-box;

  .head {
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  .btn {
    width: 56%;
    background-color: #FC3A1C;
    color: #FFFFFF;
    height: 46px;
  }
  .cancel-btn {
    width: 40%;
    background-color: #E9E9E9;
    color: #666666;
    border: none;
  }

}
</style>
