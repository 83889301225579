import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/Login.vue";
import help from "@/assets/help.js";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [{
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        component: Login,
        meta: {
            title: "登录",
            needAuth: false,
        },
    },
    // {
    //     path: "/toolMode",
    //     component: () =>
    //         import ("@/views/toolMode/shopdata.vue"),
    //     meta: {
    //         title: "数据",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/getThirdOrderList",
    //     component: () =>
    //         import ("@/views/toolMode/getThirdOrderList.vue"),
    //     meta: {
    //         title: "数据",
    //         needAuth: false,
    //     },
    // },
    
    // 商家管理
    {
        path: "/manage",
        component: () =>
            import ("@/views/Manage.vue"),
        redirect: "/manage/shop/Info",
        children: [{
                path: "shop",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                    path: "info",
                    component: () =>
                        import ("@/views/manage/shop/Info.vue"),
                    meta: {
                        title: "店铺信息",
                        needAuth: true,
                    },
                }, ],
            },
            {
                path: "goods",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "publish",
                        component: () =>
                            import ("@/views/manage/goods/Publish.vue"),
                        meta: {
                            title: "发布商品",
                            needAuth: true,
                        },
                    },
                    {
                        path: "list",
                        component: () =>
                            import ("@/views/manage/goods/List.vue"),
                        meta: {
                            title: "商品列表",
                            needAuth: true,
                            keepAlive: true
                        },
                    },
                ],
            },
            {
                path: "order",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "arrList",
                        component: () =>
                            import ("@/views/manage/order/ArrList.vue"),
                        meta: {
                            title: "全部订单",
                            needAuth: true,
                        },
                    },
                    {
                        path: "refund",
                        component: () =>
                            import ("@/views/manage/order/Refund.vue"),
                        meta: {
                            title: "退款订单",
                            needAuth: true,
                        },
                    },
                    {
                        path: "RestockLists",
                        component: () =>
                            import ("@/views/manage/order/RestockLists.vue"),
                        meta: {
                            title: "进货订单",
                            needAuth: true,
                        },
                    },
                ],
            },
            {
                path: "finance",
                component: () =>
                    import ("@/views/manage/ManageMain.vue"),
                children: [{
                        path: "report",
                        component: () =>
                            import ("@/views/manage/finance/Report.vue"),
                        meta: {
                            title: "商家财报",
                            needAuth: true,
                        },
                    },
                    {
                        path: "withdraw",
                        component: () =>
                            import ("@/views/manage/finance/Withdraw.vue"),
                        meta: {
                            title: "提现",
                            needAuth: true,
                        },
                    },
                ],
            },
            // {
            //     path: "activity",
            //     component: () => import("@/views/manage/ManageMain.vue"),
            //     children: [{
            //         path: "cutPrice/manageGoods",
            //         component: () => import("@/views/manage/activity/cutPrice/ManageGoods.vue"),
            //         meta: {
            //             title: "管理活动商品",
            //             needAuth: true,
            //         },
            //     },{
            //         path: "cutPrice/addOrEditGoods",
            //         component: () => import("@/views/manage/activity/cutPrice/AddOrEditGoods.vue"),
            //         meta: {
            //             title: "创建/编辑活动商品",
            //             needAuth: true,
            //         },
            //     }],
            // }
        ],
    },
    // {
    //     path: "/personalCenter",
    //     component: () =>
    //         import ("@/components/base/BaseEditor.vue"),
    //     meta: {
    //         title: "个人中心",
    //         needAuth: true,
    //     },
    // },

    //独立插件
    // ===========   签到  开始  ==============
    // {
    //     path: "/unit/signin/Home",
    //     component: () =>
    //         import ("@/views/unit/signin/Home.vue"),
    //     meta: {
    //         title: "首页",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/unit/signin/info",
    //     component: () =>
    //         import ("@/views/unit/signin/pages/Info.vue"),
    //     meta: {
    //         title: "签到",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/unit/signin/success",
    //     component: () =>
    //         import ("@/views/unit/signin/pages/Success.vue"),
    //     meta: {
    //         title: "签到成功",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/unit/signin/hassignIn",
    //     component: () =>
    //         import ("@/views/unit/signin/pages/HasSignIn.vue"),
    //     meta: {
    //         title: "今日已签到",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/unit/signin/write",
    //     component: () =>
    //         import ("@/views/unit/signin/pages/write.vue"),
    //     meta: {
    //         title: "表单",
    //         needAuth: false,
    //     },
    // },
    // {
    //     path: "/unit/signin/info",
    //     component: () => import("@/views/unit/signin/pages/Info.vue"),
    //     meta: { shengqing
    //         title: "签到",
    //         needAuth:false,
    //     },
    // },
    // ===========   签到  结束  ==============

    {
        //这个*路由一定要放在最后
        path: "*",
        component: () =>
            import ("@/views/error/404.vue"),
    },
];

const router = new VueRouter({
    // mode: 'history',
    // base: process.env.BASE_URL,
    // scrollBehavior: () => ({ y: 0 }),
    routes,
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // console.log("文件", to.path);
    document.title = to.meta.title || "不存在该页面";
    let token = localStorage.getItem("token");
    if (to.meta.needAuth) {
        if (token) {
            next();
        } else {
            Message.info(`访问${to.meta.title}页面需要授权，请先登录！`);
            next({
                path: "/login",
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                query: {
                    redirect: to.path,
                },
            });
        }
    } else {
        next();
    }
});

export default router;