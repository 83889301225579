//字典
export default {
    //商品审核状态: 0下架,1上架审核通过, 2 待审核,3未通过
    goodsAuditStatus: [{
        code: 0,
        text: "下架中",
    },
    {
        code: 1,
        text: "已上架",
    },
    {
        code: 2,
        text: "待审核",
    },
    {
        code: 3,
        text: "未通过审核",
    },
    ],
    //专区类别：1一号专区；2非一号专区
    zoneTypes: [{
        code: 1,
        text: "一号专区",
    },
    {
        code: 2,
        text: "普通商品",
    },
    ],
    //是否包邮：1.包邮，0不包邮
    isContainFreight: [{
        code: 0,
        text: "不包邮",
    },
    {
        code: 1,
        text: "包邮",
    },
    ],
    // 支付状态（0：支付失败 1：支付成功 2：待支付 3：线下已付款 102：申请售后）
    paymentState: [{
        code: 0,
        text: "支付失败",
    },
    {
        code: 1,
        text: "支付成功",
    },
    {
        code: 2,
        text: "待支付",
    },
        // {
        //     code: 3,
        //     text: "线下已付款",
        // },
        // {
        //     code: 102,
        //     text: "申请售后",
        // },
    ],
    //  订单状态  0待支付,3.待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
    orderState: [{
        code: 0,
        text: "待支付",
    },
    {
        code: 3,
        text: "待发货",
    },
    {
        code: 4,
        text: "待收货/已发货",
    },
    {
        code: 5,
        text: "已完成/未评价",
    },
    {
        code: 6,
        text: "已评价",
    },
    {
        code: 7,
        text: "退款成功",
    },
    {
        code: 8,
        text: "申请退款",
    },
    ],
    //   付款方式1微信,2支付宝,3paypal10购物劵,11支付宝+购物劵,12微信+购物劵
    paymentType: [{
        code: 1,
        text: "微信",
    },
    {
        code: 2,
        text: "支付宝",
    },
    {
        code: 3,
        text: "paypal",
    },
    {
        code: 10,
        text: "购物券",
    },

    {
        code: 11,
        text: "支付宝+购物券",
    },
    {
        code: 12,
        text: "微信+购物券",
    },
    {
        code: 19,
        text: "杉德支付",
    },
    {
        code: 104,
        text: "货款支付",
    },
    {
        code: 202,
        text: "杉德货款支付",
    },
    ],
    //  订单状态 0待支付,3待发货, 4待收货/已发货,5已完成/未评价,6已评价,7退款成功,8申请退款
    orderStatus: [{
        code: 0,
        text: "待支付",
    },
    {
        code: 1,
        text: "待自提",
    },
    {
        code: 3,
        text: "待发货",
    },
    {
        code: 4,
        text: "待收货/已发货",
    },
    {
        code: 5,
        text: "已完成/未评价",
    },
    {
        code: 6,
        text: "已评价",
    },
        // {
        //     code: 7,
        //     text: "退款成功",
        // },
        // {
        //     code: 8,
        //     text: "申请退款",
        // },
    ],
    //退款状态
    refundState: [{
        code: 0,
        text: "未审核",
    }, {
        code: 1,
        text: "同意",
    }, {
        code: 2,
        text: "不同意",
    }],
    //商品参与活动状态：0已下架 1上架中 2待上架
    goodsActivityStatus: [{
        code: 0,
        text: "已下架"
    }, {
        code: 1,
        text: "上架中"
    }, {
        code: 2,
        text: "待上架"
    }],
    //进货订单 ---订单状态
    restockOrderState: [{
        code: 0,
        text: '待支付'
    },
    {
        code: 3,
        text: '待发货'
    },
    {
        code: 4,
        text: '待入仓'
    },
    {
        code: 5,
        text: '已入仓'
    }
    ],
    // 开售状态
    canBuyStatus: [{
        code: 1,
        text: '已开售'
    }, {
        code: 0,
        text: '未开售'
    }],
    //物流状态
    wuliuStatus: [{
        code: 0,
        text: '无记录'
    },
    {
        code: 1,
        text: '揽收'
    },
    {
        code: 2,
        text: '运输中'
    },
    {
        code: 3,
        text: '派件中'
    },
    {
        code: 4,
        text: '已签收'
    },
    {
        code: 5,
        text: '拒签'
    },
    {
        code: 6,
        text: '疑难'
    },
    {
        code: 7,
        text: '退回'
    },
    {
        code: 8,
        text: '退签'
    },
    {
        code: 9,
        text: '转单'
    },
    ]

};