import axios from "axios";
import app from "@/main.js";
import qs from "qs";
import { Loading } from 'element-ui';

let loading = null; //使用Loading.service时，不能定义成const常量
let loadingCount = 0;

let startLoading = () => {
    loading = Loading.service({
        lock: true,
        text: '玩命加载中……',
        background: 'rgba(0, 0, 0, 0.5)'
    });
};

let endLoading = () => {
    loading.close();
    loading = null;
};

let showLoading = () => {
    if (loadingCount === 0) {
        startLoading();
    }
    loadingCount += 1;
};

let hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        endLoading();
    }
};


//获取当前环境配置项中的地址，会自动追加到接口上
let http = axios.create({
    baseURL:process.env.VUE_APP_API,
    timeout: 20000, //axios timeout超时设置，默认为0
    withToken: true, //这里是自行添加的，因为后端对不需要token的接口，前端传了token，后端会提示异常：token格式不对或token过期等。
    // withCredentials: false,   //表示跨域请求时是否需要使用凭证，默认false
    headers: {
        version: '2.0.6',
        app: 'web'
    }

});

/****** request拦截器==>对请求参数做处理 ******/
http.interceptors.request.use(
    req => {
        showLoading();
        if (req.method === 'post') req.data = qs.stringify({...req.data });
        if (req.withToken)  req.headers['Authorization'] = localStorage.getItem("token");
        return req;
    },
    err => {
        return err;
    });
/****** respone拦截器==>对响应做处理 ******/
http.interceptors.response.use(
    res => {
        hideLoading();
        let { status, data } = res;
        if (status >= 200 && status < 300) {
            let { code, message } = data;
            if (Number(code) === 1011006 || Number(code) === 1011008) {
                app.$message.error(message);
                app.$router.push("/login");
            } else {
                return data;
            }
        }
    },
    err => {
        hideLoading();
        app.$message.error(err);
        return err;
    }
);
export default http;